import {
  cilBell,
  cilLockLocked
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage
} from '@coreui/react'
import { useEffect, useState } from 'react'
import { getUser, logoutUser } from 'src/services/UserService'
import { handleApiErrors } from 'src/utils/HandleErrors'
import avatar9 from './../../assets/images/avatars/avatar9.png'
import arrowDown from './../../assets/images/dashboard/arrow-down.png'
import { deleteNotification, listNotifications, updateNotification } from 'src/services/NotificationService'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

const AppHeaderDropdown = () => {
  const [user, setuser] = useState({})
  const [notifications, setNotifications] = useState([])
  const [count, setCount] = useState(0)

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response.status === 200) {
        setuser(response.data)
      }
    } catch (error) {
      handleApiErrors(error)
      console.error('Error:', error);
    }
  }

  const getNotifications = async () => {
    try {
      const response = await listNotifications();
      if (response.status === 200) {
        const allNotifications = response.data.notifications;
        const unreadCount = response.data.total;
        setNotifications(allNotifications);
        setCount(unreadCount);
      }
    } catch (error) {
      handleApiErrors(error);
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    // getUserDetails();
    getNotifications();
  }, [])

  const handleLogout = async () => {
    try {
      const response = await logoutUser();
      if (response.status === 204) {
        window.location = '/login'
      }
    } catch (error) {
      handleApiErrors(error);
      console.error('Error:', error);
    }
  }

  const handleUpdateNotification = async (id) => {
    try {
      const response = await updateNotification(id);
      if (response) {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === id
              ? { ...notification, read_at: response.data.notification.read_at }
              : notification
          )
        );

        const updatedUnreadCount = notifications.filter((n) => n.read_at === null && n.id !== id).length;
        setCount(updatedUnreadCount);

        return toast.success("Notification Read");
      }
    } catch (error) {
      handleApiErrors(error);
      console.error('Error:', error);
    }
  };


  const handleDeleteNotification = async (id) => {
    try {
      const response = await deleteNotification(id);
      if (response) {
        const deletedNotification = notifications.find((notification) => notification.id === id);

        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification.id !== id)
        );

        if (deletedNotification?.read_at === null) {
          setCount((prevCount) => prevCount - 1);
        }
      }
      return toast.error("Notification Cancelled")
    } catch (error) {
      handleApiErrors(error);
      console.error('Error:', error);
    }
  };

  return (
    <>
      <CDropdown variant="nav-item" className='d-flex align-items-center'>
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <div className='d-flex align-items-center'>
            <CIcon icon={cilBell} size="lg" />
            <CBadge color="success" className="ms-2" style={{position:'absolute',top: '1%',left: '.6rem'}}>
            {count}
          </CBadge>
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end" style={{ minWidth: '300px',maxHeight: '350px', overflowY: 'auto' }}>
          <h5 className='ms-3 fw-bold mt-3'>Notifications</h5>
          <hr />
          {notifications?.length > 0 ? (
            notifications.map((notification, index) => (
              <CDropdownItem key={index}>
                <p style={{fontSize: '14px', margin: '0'}}>{notification.data.message}</p>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                  <small className="text-muted" style={{fontSize:'10px'}}>
                    {moment(notification.created_at).fromNow()}
                  </small>
                  <div>
                  {notification.read_at	== null && <button className='btn btn-sm btn-success text-white me-2' onClick={()=>handleUpdateNotification(notification.id)}>Read</button>}
                  <button className='btn btn-sm btn-danger text-white' onClick={()=>handleDeleteNotification(notification.id)}>Cancel</button>
                  </div>
                </div>
                <hr className='m-0 mt-2' />
              </CDropdownItem>
            ))
          ) : (
            <CDropdownItem>
              <h6>No notifications</h6>
            </CDropdownItem>
          )}
        </CDropdownMenu>
      </CDropdown>

      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <div className='d-flex align-items-center'>
            <CAvatar src={avatar9} size='lg' style={{ width: '2.1rem', margin: '0 1rem' }} />
            <span style={{ fontWeight: '600', fontSize: '18px', color: '#32323B' }}>{user?.name}</span>
            <CImage src={arrowDown} className='ms-2' />
          </div>
        </CDropdownToggle>

        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownHeader className="bg-light fw-semibold py-2">{user?.name}</CDropdownHeader>
          <CDropdownDivider />
          <CDropdownItem onClick={handleLogout}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Log Out
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

export default AppHeaderDropdown
