import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import  logo  from 'src/assets/images/dashboard/logo.png'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import { PERMISSION_BANK_ACCOUNT_CREATE, PERMISSION_BANK_ACCOUNT_LIST, PERMISSION_BANK_CHECK_CREATE, PERMISSION_BANK_CHECK_LIST, PERMISSION_BANK_CREATE, PERMISSION_BANK_LIST, PERMISSION_DOCUMENT_CREATE, PERMISSION_DOCUMENT_LIST, PERMISSION_DRAW_REQUEST_CREATE, PERMISSION_DRAW_REQUEST_LIST, PERMISSION_FUNDS_CONTROL_ACCOUNT_CREATE, PERMISSION_FUNDS_CONTROL_ACCOUNT_LIST, PERMISSION_INSPECTION_CREATE, PERMISSION_INSPECTION_LIST, PERMISSION_OFFICE_CREATE, PERMISSION_OFFICE_LIST, PERMISSION_PARTY_CREATE, PERMISSION_PARTY_LIST, PERMISSION_PRE_CONSTRUCTION_REVIEW_CREATE, PERMISSION_PRE_CONSTRUCTION_REVIEW_LIST, PERMISSION_PUBLIC_FORM_SUBMISSION_CREATE, PERMISSION_PUBLIC_FORM_SUBMISSION_LIST, PERMISSION_RECEIPT_CREATE, PERMISSION_RECEIPT_LIST, PERMISSION_REPORT_BANK_ACCOUNT_VIEW, PERMISSION_REPORT_BANK_CHECK_VIEW, PERMISSION_REPORT_CONSTRUCTION_COST_VIEW, PERMISSION_REPORT_FCA_BALANCE_SHEET_VIEW, PERMISSION_REPORT_INSPECTION_VIEW, PERMISSION_REPORT_PORTFOLIO_RECAP_VIEW, PERMISSION_REPORT_RECONCILIATION, PERMISSION_ROLE_PERMISSION_CREATE, PERMISSION_ROLE_PERMISSION_LIST, PERMISSION_USER_CREATE, PERMISSION_USER_LIST } from 'src/utils/LmfceConstants'

// sidebar nav config
import navigation from '../_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebar.sidebarShow)
  const permissions = useSelector((state) => state.permission.permissions) || []

  const filterNavigation = (navigation) => {
    return navigation.reduce((acc, navItem) => {

      // Define the navigation filtering rules in an object for easy lookup
      const permissionRules = {
        'Applications': [PERMISSION_PUBLIC_FORM_SUBMISSION_LIST, PERMISSION_PUBLIC_FORM_SUBMISSION_CREATE],
        'Fund Control': [PERMISSION_FUNDS_CONTROL_ACCOUNT_LIST, PERMISSION_FUNDS_CONTROL_ACCOUNT_CREATE],
        'PCR Report': [PERMISSION_PRE_CONSTRUCTION_REVIEW_LIST, PERMISSION_PRE_CONSTRUCTION_REVIEW_CREATE],
        'Inspections Only': [PERMISSION_FUNDS_CONTROL_ACCOUNT_LIST, PERMISSION_FUNDS_CONTROL_ACCOUNT_CREATE],
        'Parties': [PERMISSION_PARTY_LIST, PERMISSION_PARTY_CREATE],
        'Draw Request': [PERMISSION_DRAW_REQUEST_LIST, PERMISSION_DRAW_REQUEST_CREATE],
        'Deposits': [PERMISSION_RECEIPT_LIST, PERMISSION_RECEIPT_CREATE],
        'Checks': [PERMISSION_BANK_CHECK_LIST, PERMISSION_BANK_CHECK_CREATE],
        'Inspection Summary': [PERMISSION_INSPECTION_LIST, PERMISSION_INSPECTION_CREATE],
        'Bank': [PERMISSION_BANK_LIST, PERMISSION_BANK_CREATE],
        'Bank Account': [PERMISSION_BANK_ACCOUNT_LIST, PERMISSION_BANK_ACCOUNT_CREATE],
        'Roles': [PERMISSION_ROLE_PERMISSION_LIST, PERMISSION_ROLE_PERMISSION_CREATE],
        'Offices': [PERMISSION_OFFICE_LIST, PERMISSION_OFFICE_CREATE],
        'Documents': [PERMISSION_DOCUMENT_LIST, PERMISSION_DOCUMENT_CREATE],
        'Users': [PERMISSION_USER_LIST, PERMISSION_USER_CREATE],
        'Construction Cost': [PERMISSION_REPORT_CONSTRUCTION_COST_VIEW],
        'Hard Cost Budget': [PERMISSION_REPORT_CONSTRUCTION_COST_VIEW],
        'Portfolio Recap': [PERMISSION_REPORT_PORTFOLIO_RECAP_VIEW],
        'Disbursements': [PERMISSION_REPORT_BANK_CHECK_VIEW],
        'Inspection Details': [PERMISSION_REPORT_INSPECTION_VIEW],
        'FC Balance Sheet': [PERMISSION_REPORT_FCA_BALANCE_SHEET_VIEW],
        'Reconciliation': [PERMISSION_REPORT_RECONCILIATION],
      };

      // Check if the nav item requires permission and if the user has that permission
      const requiredPermissions = permissionRules[navItem.name];
      if (requiredPermissions && !requiredPermissions.some(permission => permissions.includes(permission))) {
        return acc; // Skip item if permission is not met
      }

      // Recursion for nested items
      if (navItem.items) {
        const filteredItems = filterNavigation(navItem.items);
        if (filteredItems.length > 0) {
          acc.push({ ...navItem, items: filteredItems });
        } else {
          acc.push({ ...navItem, items: filteredItems });
        }
      } else {
        acc.push(navItem);
      }

      return acc;
    }, []);
  };

  const filteredNavigation = filterNavigation(navigation);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" style={{height: '3rem', margin: '1.5rem 0', background: '#025346'}} to="/">
        {/* <CIcon className="sidebar-brand-full" icon={logo} height={35} /> */}
        <img src={logo} />
        {/* <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={filteredNavigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
