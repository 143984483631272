import {
  cilApplications,
  cilBank,
  cilBarChart,
  cilBuilding,
  cilCalculator,
  cilCamera,
  cilControl,
  cilDescription,
  cilFunctions,
  cilGroup,
  cilIndustry,
  cilList,
  cilMobileLandscape,
  cilReportSlash,
  cilSpreadsheet,
  cilUser,
  cilWallet
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavGroup, CNavItem } from '@coreui/react'
import { ReactComponent as Daily } from 'src/assets/images/sidebar/calendar.svg'
import { ReactComponent as Dashboard } from 'src/assets/images/sidebar/dashboard.svg'
import { ReactComponent as Fca } from 'src/assets/images/sidebar/fca.svg'
import { ReactComponent as Parties } from 'src/assets/images/sidebar/parties.svg'
import { ReactComponent as Reports } from 'src/assets/images/sidebar/reports.svg'
import { ReactComponent as Settings } from 'src/assets/images/sidebar/settings.svg'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <Dashboard className='ms-2 me-3' stroke="#025346" />,
  },
  {
    component: CNavGroup,
    name: 'Projects',
    icon: <Fca className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Applications',
        to: '/application',
      },
      {
        icon: <CIcon icon={cilList} customClassName="nav-icon" stroke="#025346" />,
        component: CNavItem,
        name: 'Fund Control',
        to: '/funds-control-account',
      },
      {
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'PCR Report',
        to: '/pcr',
      },
      {
        icon: <CIcon icon={cilCamera} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Inspections Only',
        to: '/fca/inspection',
      },
    ]
  },
  {
    component: CNavItem,
    name: 'Parties',
    to: '/party',
    icon: <Parties className='ms-2 me-3' stroke="#025346" />,
  },
  {
    component: CNavGroup,
    name: 'Daily',
    icon: <Daily className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Draw Request',
        to: '/draw-request',
      },
      // {
      //   icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
      //   component: CNavItem,
      //   name: 'Budget Transfer',
      //   to: '/fca-budget-transfer',
      // },
      {
        icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Deposits',
        to: '/deposit',
      },
      {
        icon: <CIcon icon={cilMobileLandscape} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Checks',
        to: '/check',
      },
      {
        icon: <CIcon icon={cilMobileLandscape} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Party Checks',
        to: '/party-check',
      },
      {
        icon: <CIcon icon={cilCamera} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Inspection Summary',
        to: '/inspection',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Reports',
    icon: <Reports className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Construction Cost',
        to: '/report/construction-cost'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Hard Cost Budget',
        to: '/report/hard-cost-budget'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Portfolio Recap',
        to: '/report/portfolio-recap'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Disbursements',
        to: '/report/bank-check'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Deposits',
        to: '/report/receipt'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Bank Account',
        to: '/report/bank-account'
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
        name: 'Inspection Details',
        to: '/report/inspection-detail'
      },
      {
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'FC Balance Sheet',
        to: '/report/fca-balance-sheet',
      },
      {
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Reconciliation',
        to: '/report/reconciliation',
      },
    ]
  },
  {
    component: CNavGroup,
    name: 'Settings',
    icon: <Settings className='ms-2 me-3' stroke="#025346" />,
    items: [
      {
        icon: <CIcon icon={cilIndustry} customClassName="nav-icon" />,
        component: CNavGroup,
        name: 'Banks & Accounts',
        items: [
          {
            icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
            component: CNavItem,
            name: 'Bank',
            to: '/bank',
          },
          {
            icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
            component: CNavItem,
            name: 'Bank Account',
            to: '/bank-account',
          },
        ]
      },
      {
        component: CNavItem,
        name: 'Profile',
        to: '/profile',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Roles',
        to: '/role',
        icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
      },
      {
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Services',
        to: '/service',
      },
      {
        icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Offices',
        to: '/office',
      },
      {
        icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Documents',
        to: '/document',
      },
      {
        icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
        component: CNavItem,
        name: 'Users',
        to: '/user',
      },
      // {
      //   icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
      //   component: CNavGroup,
      //   name: 'Inspector',
      //   items: [
      //     {
      //       icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
      //       component: CNavItem,
      //       name: 'Add Inspector',
      //       to: '/inspector/add',
      //     },
      //     {
      //       icon: <CIcon icon={cilZoom} customClassName="nav-icon" />,
      //       component: CNavItem,
      //       name: 'View Inspectors',
      //       to: '/inspector',
      //     },
      //   ]
      // },
    ],
  },
]

export default _nav
